import { getPlainText } from "@/utils/articleHtmlSplit";
import Head from "next/head";

const BreadcrumbSchema = ({ breadcrumbList = [] }) => {
  let itemList = [
    {
      "@type": "ListItem",
      position: 1,
      item: {
        name: "Home",
        "@url": `${process.env.NEXT_PUBLIC_WEBSITE_URL}`,
        // "@id": `${process.env.NEXT_PUBLIC_WEBSITE_URL}`,
      },
    },
  ];

  if (breadcrumbList.length > 0) {
    breadcrumbList?.map((item, index) => {
      itemList.push({
        "@type": "ListItem",
        position: index + 2,
        item: {
          name: getPlainText(item?.title),
          "@url": item?.canonical_url?.includes(
            process.env.NEXT_PUBLIC_WEBSITE_URL
          )
            ? `${item?.canonical_url}`
            : process.env.NEXT_PUBLIC_WEBSITE_URL + `${item?.canonical_url}`,
          // "@id": item?.canonical_url?.includes(
          //   process.env.NEXT_PUBLIC_WEBSITE_URL
          // )
          //   ? `${item?.canonical_url}`
          //   : process.env.NEXT_PUBLIC_WEBSITE_URL + `${item?.canonical_url}`,
        },
      });
    });
  }

  return (
    <Head>
      <script
        id="BreadcrumbSchema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: itemList,
          }),
        }}
      />
    </Head>
  );
};

export default BreadcrumbSchema;
