import { convert } from "html-to-text";

export const descriptionSplitByPTag = (str) => {
  try {
    if (!str) {
      return [];
    }
    let outputArr = [];
    outputArr = str?.split("</p>");
    outputArr.map((data, index) => {
      outputArr[index] = outputArr[index] + "</p>";
    });

    let totalo = 0; //Total opening blockquote tags
    let totalc = 0; // Total closing blockquote tags
    for (let x = 0; x < outputArr.length; x++) {
      const opening = outputArr[x].split("<blockquote").length - 1;
      /*  Counts how many opening and closing blockquote tags
            are there on the current position of the array  */
      const closing = outputArr[x].split("</blockquote>").length - 1;
      totalo = opening;
      totalc = closing;

      if (closing !== opening) {
        let ind = x;
        /*Keep merging all the indexes ahead to outputArr[ind] 
               till the time we achieve closing===opening */
        while (true) {
          x++;
          outputArr[ind] = outputArr[ind] + outputArr[x];
          const openingInternal = outputArr[x]?.split("<blockquote").length - 1;
          const closingInternal =
            outputArr[x]?.split("</blockquote>").length - 1;
          outputArr[x] = "";
          totalo = totalo + openingInternal;
          totalc = totalc + closingInternal;

          if (totalo === totalc) {
            totalo = 0;
            totalc = 0;
            break;
          }
        }
      }
    }
    let finalArray = [];

    for (let x = 0; x < outputArr.length; x++) {
      //Remove all indexes whose length have become 0
      if (outputArr[x].length !== 0) {
        finalArray.push(outputArr[x]);
      }
    }
    return finalArray;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export function splitHtmlByParagraph(htmlString) {
  // Regular expression to match content between <p> and </p> tags
  const regex = /<p>(.*?)<\/p>/g;

  // Array to hold the extracted paragraphs
  let paragraphs = [];

  // Extract paragraphs
  let match;
  while ((match = regex.exec(htmlString)) !== null) {
    paragraphs.push(match[1]);
  }

  return paragraphs;
}
export function splitHTMLArticle(articleHTML) {
  function findSubstringIndices(inputString, substring) {
    const indices = [];
    let index = inputString.indexOf(substring);

    while (index !== -1) {
      indices.push(index);
      index = inputString.indexOf(substring, index + 1);
    }

    return indices;
  }

  const inputString = articleHTML; //articleHtmlgoesHere
  const substring = "<p>";
  const substring1 = "</p>";

  const pOpening = findSubstringIndices(inputString, substring);
  const pClosing = findSubstringIndices(inputString, substring1);

  var finalArray = [];
  let sIndex = 0;
  if (pOpening[0] !== 0) {
    finalArray.push(inputString.substring(0, pOpening[0]));
  }
  finalArray.push(inputString.substring(pOpening[0], pClosing[0] + 4));
  sIndex++;
  while (sIndex < pOpening.length) {
    if (pClosing[sIndex - 1] + 4 !== pOpening[sIndex]) {
      const embedInBtw = inputString.substring(
        pClosing[sIndex - 1] + 4,
        pOpening[sIndex]
      );
      finalArray.push(embedInBtw);
    }
    const pTag = inputString.substring(pOpening[sIndex], pClosing[sIndex] + 4);
    finalArray.push(pTag);
    sIndex++;
  }
  return finalArray;
}

export function getPlainText(htmlText) {
  try {
    const plainText = convert(htmlText, {
      wordwrap: false, // Disable word wrapping
      ignoreImage: true, // Ignore images
      preserveNewlines: true, // Preserve newlines
      uppercaseHeadings: false, // Do not convert headings to uppercase
    });
    return plainText;
  } catch (err) {
    console.log("err while converting to plain text", err);
    return htmlText;
  }
}

export function capitalizeWords(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
